.burger {
  border: 0;
  background: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
  border-bottom: 4px solid currentColor;
  width: 28px;
  z-index: 999;
  position:fixed;
  left: 1em;
  padding-top:1.7em;
  transition: border-bottom 1s ease-in-out;
  -webkit-transition: border-bottom 1s ease-in-out;
}
.burger::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.burger:before {
  content: "";
  display: block;
  border-bottom: 4px solid currentColor;
  width: 100%;
  margin-bottom: 5px;
  transition: transform 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
}
.burger:after {
  content: "";
  display: block;
  border-bottom: 4px solid currentColor;
  width: 100%;
  margin-bottom: 5px;
  transition: transform 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
}
.burger.menuopen {
  border-bottom: 4px solid transparent;
  transition: border-bottom 0.8s ease-in-out;
  -webkit-transition: border-bottom 0.8s ease-in-out;
}
.burger.menuopen:before {
  transform: rotate(-405deg) translateY(1px) translateX(-3px);
  -webkit-transform: rotate(-405deg) translateY(1px) translateX(-3px);
  transition: transform 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
}
.burger.menuopen:after {
  transform: rotate(405deg) translateY(-4px) translateX(-5px);
  -webkit-transform: rotate(405deg) translateY(-4px) translateX(-5px);
  transition: transform 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
}
